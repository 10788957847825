import '@libero/plugins/ant-design';
import '@libero/plugins/chart';
import '@libero/plugins/dayjs';
import '@libero/theme/app.scss';

import { i18n } from '@libero/i18n/index';
import { query, queryOptions } from '@libero/plugins/query';
import { registerSentry } from '@libero/plugins/sentry';
import { initializeAnalytics } from '@libero/utilities/analytics';
import { createPinia } from 'pinia';
import { createApp } from 'vue';

import App from './App.vue';
import { router } from './router';

initializeAnalytics(import.meta.env.VITE_GA_CITYVIEW);

const app = createApp(App);
const pinia = createPinia();

registerSentry(app, router);

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(query, queryOptions);

app.mount('#app');
